<template>
    <div class="apptcomt">

        <el-row style="margin-bottom:30px">
            <div style="display: flex;">
                <Headbuttom></Headbuttom>
                <el-button style="margin-left:20px" size="small" type="primary" @click="onSubmit">{{this.$t('devtable.szkqcs')}}</el-button>
            </div>
        </el-row>
        <el-row>
            <el-form ref="form" :model="form" label-width="170px">
            
            <div style="display: flex;">
                <el-form-item :label="$t('devtable.cddt')">
                    <el-select v-model="form.late_normal" :placeholder="$t('devtable.qxz')">
                        <el-option v-for="(item,index) in listone" :key="index" :label="item.label" :value="item.value"></el-option>
                        
                    </el-select>
                    <span class="cont_row">{{$t('devtable.ynbscd')}}</span>
                </el-form-item>
                <el-form-item :label="$t('devtable.cddt')">
                    <el-select v-model="form.late_completion" :placeholder="$t('devtable.qxz')">
                        <el-option v-for="(item,index) in listrue" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="cont_row">{{$t('devtable.ysskg')}}</span>
                    <el-select v-model="form.late_completion_days" :placeholder="$t('devtable.qxz')">
                        <el-option v-for="(item,index) in listday" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>

                </el-form-item>
            </div>
            <div style="display: flex;">
                <el-form-item :label="$t('devtable.tq')">
                    <el-select v-model="form.leave_normal" :placeholder="$t('devtable.qxz')">
                        <el-option v-for="(item,index) in listone" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="cont_row">{{$t('devtable.ynbsct')}}</span>
                </el-form-item>
                <el-form-item :label="$t('devtable.tq')">
                    <el-select v-model="form.leave_completion" :placeholder="$t('devtable.qxz')">
                       <el-option v-for="(item,index) in listrue" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="cont_row">{{$t('devtable.ysskg')}}</span>
                    <el-select v-model="form.leave_completion_days" :placeholder="$t('devtable.qxz')">
                        <el-option v-for="(item,index) in listday" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>

                </el-form-item>
            </div>
            
            <el-form-item :label="$t('devtable.bk')">
                <el-select v-model="form.makeup_times" :placeholder="$t('devtable.qxz')">
                    <el-option v-for="(item,index) in bukalist" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <span class="cont_row">{{$t('devtable.cmyyx')}}</span>
            </el-form-item>

            <el-form-item :label="$t('devtable.gzsjjs')">
                <el-select v-model="form.overtime" :placeholder="$t('devtable.qxz')">
                    <el-option v-for="(item,index) in jiablist" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <span class="cont_row">{{$t('devtable.hsksjb')}}</span>
            </el-form-item>
           
           <el-form-item :label="$t('devtable.zwzzyxsksj')" label-width="210px">
                <el-select v-model="form.card_valid_time" :placeholder="$t('devtable.qxz')">
                    <el-option v-for="(item,index) in jiablist" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item>
                
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
        </el-row>

        <Apptadiolg ref="apptadiolg"></Apptadiolg>

    </div>
</template>

<script>
import Apptadiolg from './components/attparamdiolg'
  export default {
    data() {
      return {
          UserInfo:'',
		  language:window.localStorage.getItem('language'),
        form: {
            late_normal:'5', /*迟到多少分钟不算迟到 */
            late_completion:'60', //late_completion
            late_completion_days:'0.5', /*迟到算旷工天数*/
            leave_normal:'5',///*早退多少分钟不算早退*/
            leave_completion:'60', /*早退多少分钟算旷工*/
            leave_completion_days:'0.5', /*早退算旷工天数*/

            makeup_times:'0',/*每月最多允许补卡次数*/
            overtime:'-1',/*overtime工作时间结束后多少分钟开始算加班*/
            card_valid_time:'-1',/*最早最晚刷卡时间*/
        },
        listone:[
            { value: '1',label: '一' + this.$t('devtable.fz') },
            { value: '5',label: '5'+this.$t('devtable.fz') },
            { value: '10',label: '10' +this.$t('devtable.fz') },
            { value: '30',label: '30'+this.$t('devtable.fz') },
            { value: '60',label: '1' +this.$t('devtable.xs') },
            { value: '120',label: '2'+this.$t('devtable.xs') },
        ],
        listrue:[
            { value: '10',label: '10' +this.$t('devtable.fz') },
            { value: '20',label: '20' +this.$t('devtable.fz') },
            { value: '30',label: '30' +this.$t('devtable.fz') },
            { value: '60',label: '1' +this.$t('devtable.xs') },
            { value: '90',label: '1.5' +this.$t('devtable.xs') },
            { value: '120',label: '2' + this.$t('devtable.xs') },
        ],
        listday:[
            { value: '0',label: '0' +  this.$t('devtable.t') },
            { value: '0.5',label: '0.5' + this.$t('devtable.t')},
            { value: '1',label: '1' + this.$t('devtable.t') },
        ],
        bukalist:[
            { value: '0',label: this.$t('devtable.bxcs') },
            { value: '1',label: '1' + this.$t('devtable.cs') },
            { value: '2',label: '2' +this.$t('devtable.cs') },
            { value: '3',label: '3' +this.$t('devtable.cs') },
            { value: '4',label: '4'+this.$t('devtable.cs') },
        ],
        jiablist:[
            { value: '-1',label:  this.$t('devtable.wsz')},
            { value: '0',label: '0'+this.$t('devtable.fz') },
            { value: '30',label: '30'+this.$t('devtable.fz') },
            { value: '60',label: '1'+this.$t('devtable.xs') },
            { value: '120',label: '2'+this.$t('devtable.xs') },
            { value: '180',label: '3'+this.$t('devtable.xs') },
        ]
      }
    },
    components:{
        Apptadiolg
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getattParam()
    },
    methods: {
        getattParam(){
            let data={
                method:"att.config.get",
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    let data = res.data.data
                    this.form.late_normal = data.late_normal.toString()
                    this.form.late_completion = data.late_completion.toString()
                    this.form.late_completion_days = data.late_completion_days.toString()
                    this.form.late_normal= data.late_normal.toString()
                    this.form.leave_completion= data.leave_completion.toString()
                    this.form.leave_completion_days = data.leave_completion_days.toString()
                    this.form.leave_normal = data.leave_normal.toString()
                    this.form.makeup_times= data.makeup_times.toString()
                    this.form.overtime= data.overtime.toString()
                    this.form.card_valid_time=data.card_valid_time.toString()
                }
            })
        },
        onSubmit() {
            this.$refs.apptadiolg.dialogVisible=true
        }
    }
  }
</script>
<style lang="less" scoped>
.apptcomt{
    padding: 20px;
    background-color: #fff;
    // display: flex;
}
.cont_row{
    margin-left: 10px;
    margin-right: 10px;
}
.apptcomt /deep/ .el-select{
    width: 140px;
}
</style>